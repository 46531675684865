import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import resourcesFallback from 'i18next-resources-fallback'
import { initReactI18next } from 'react-i18next'

import { resources } from '@/locales'

const remotePath =
  'https://cdn.simplelocalize.io/58a7f345250649c7ac0d2c89c07aceb0/_latest/{{lng}}/{{ns}}'

i18n
  // TODO: support ja only for now
  // .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    backend: {
      backends: [HttpBackend],
      backendOptions: [
        {
          loadPath: remotePath,
        },
      ],
    },

    defaultNS: 'translation',
    ns: ['translation'],
    lng: 'ja',
    fallbackLng: 'en',
    supportedLngs: ['ja', 'en'],
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n

resourcesFallback(i18n, resources)
