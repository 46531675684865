import { useCurrentUser } from '@/hooks/auth'
import { config, getApiEnv, getAppEnv } from '@/lib/config'
import { motion, useDragControls } from 'framer-motion'
import { useRef } from 'react'
import { useStore } from 'zustand'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip'
import { debugStore } from './store'

export default function DebugWidget() {
  const { data: user } = useCurrentUser()

  const appEnv = getAppEnv()
  const apiEnv = getApiEnv()

  useStore(debugStore)

  if (
    (appEnv !== 'prod' || user?.isAdmin) &&
    !debugStore.getState().initialized
  ) {
    debugStore.setState({ initialized: true, showIndicator: true })
  }

  const dragControls = useDragControls()
  const constraintRef = useRef<HTMLDivElement>(null)

  if (!debugStore.getState().showIndicator) return null

  const indicator = (
    <>
      <div
        className="-z-10 fixed inset-0 pointer-events-none"
        ref={constraintRef}
      />
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <motion.div
              id="debug-widget"
              className="fixed text-sm left-4 bottom-4 z-20 flex gap-1 opacity-70"
              drag
              dragControls={dragControls}
              dragElastic={1}
              style={{
                touchAction: 'none',
              }}
              dragConstraints={constraintRef}
            >
              <span>+ app:</span>
              <span
                className={
                  appEnv === 'prod'
                    ? 'text-red-500'
                    : appEnv === 'preview'
                      ? 'text-yellow-500'
                      : 'text-slate-500'
                }
              >
                {appEnv}
              </span>
              <span>api:</span>
              <span
                className={
                  apiEnv === 'prod' ? 'text-red-500' : 'text-slate-500'
                }
              >
                {apiEnv}
              </span>
            </motion.div>
          </TooltipTrigger>
          <TooltipContent
            className={'bg-background bg-opacity-45 text-foreground shadow'}
          >
            {/* <div className="font-bold">click to show debug widget</div> */}
            {config.commitHash && (
              <div>commit: {config.commitHash?.slice(0, 8)}</div>
            )}
            {user?.isAdmin && (
              <div className="text-yellow-500">
                (You are admin. Operate with caution.)
              </div>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  )

  return indicator
}
