import type { ResourceKey, ResourceLanguage } from 'i18next'

const en = import.meta.glob('./en/*.json', { eager: true, import: 'default' })
const ja = import.meta.glob('./ja/*.json', { eager: true, import: 'default' })

const handleResources = (resources: Record<string, unknown>) => {
  const result: ResourceLanguage = {}
  for (const key in resources) {
    const match = key.match(/.*\/(.*)\.json/)
    if (match) {
      result[match[1]] = resources[key] as ResourceKey
    }
  }
  return result
}

export const resources = {
  en: handleResources(en),
  ja: handleResources(ja),
}
