import { useCommonTokenHandler } from '@/hooks/auth'
import { Outlet } from 'react-router-dom'
import '@/lib/i18n'
import '@/lib/track'
import DebugWidget from '@/components/admin/debug-widget'
import { ThemeProvider } from '@/components/common/theme-provider'

function Root() {
  useCommonTokenHandler()

  return (
    <>
      <ThemeProvider>
        <Outlet />
        <DebugWidget />
      </ThemeProvider>
    </>
  )
}

export const Component = Root
